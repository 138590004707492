$trans: all .25s ease;

@media (max-width: 1599.98px) {

  .top_header .header_slogan h4 {
    font-size: 28px;
  }

  .main_screen h1 {
    font-size: 78px;
  }

  .pools_2 .top_form input,
  .pools_3 .top_form input,
  .pools_4 .top_form input,
  .pools_5 .top_form input,
  .equipment .top_form input,
  .coating .top_form input,
  .calculate_form .top_form input,
  .warranty .top_form input,
  .contacts .top_form input {
    width: 340px;
  }

  .pools1_bottom h2 {
    font-size: 32px;
  }

  .col_equipment h4 {
    font-size: 30px;
  }

  .col_equipment p {
    font-size: 22px;
  }

  .calculate_form h4 {
    font-size: 32px;
  }

  .calculate_form h4 span:before {
    left: -94px;
    top: -10px;
  }

  .calculate_form .col_left .input_wrap span,
  .calculate_form .col_left .checkbox_wrap .ui-button,
  .calculate_form .col_center .checkbox_wrap .ui-button,
  .calculate_form .col_right .checkbox_wrap .ui-button {
    font-size: 22px;
  }

  .works p {
    font-size: 20px;
  }
}

@media (max-width: 1399.98px) {

  .top_header .header_slogan h4,
  .top_header .header_slogan h5 {
    font-size: 21px;
  }

  .main_screen h1 {
    font-size: 66px;
  }

  .main_screen_bottom p {
    font-size: 24px;
  }

  .pools_1_top h3 {
    font-size: 29px;
  }

  .pools1_bottom h2 {
    font-size: 27px;
  }

  .pools_2 .top_form input, .pools_3 .top_form input, .pools_4 .top_form input, .pools_5 .top_form input, .equipment .top_form input, .coating .top_form input, .calculate_form .top_form input, .warranty .top_form input, .contacts .top_form input {
    width: 275px;
    padding-left: 15px;
    font-size: 20px;
  }

  .pools_2 .top_form button,
  .pools_3 .top_form button,
  .pools_4 .top_form button,
  .pools_5 .top_form button,
  .equipment .top_form button,
  .coating .top_form button,
  .calculate_form .top_form button,
  .warranty .top_form button,
  .works .orange_btn,
  .contacts .top_form button {
    font-size: 20px;
  }

  .row_pools1_items {
    flex-wrap: wrap;
  }

  .row_pools1_items .col-3 {
    max-width: 50%;
    flex-basis: 50%;
    margin-bottom: 30px;
  }

  .pools3_item h4 {
    font-size: 26px;
  }

  .pools3_item p {
    font-size: 17px;
  }

  .pools4_item h4 {
    font-size: 28px;
  }

  .pools4_item p {
    font-size: 22px;
  }

  .pools4_item h5 {
    font-size: 22px;
  }

  .pools4_item ul li {
    font-size: 22px;
  }

  .pools_5 .col-7 {
    flex-basis: 100%;
    max-width: 100%;
  }

  .pools_5 h2 {
    font-size: 46px;
    margin-bottom: 30px;
    text-align: center;
  }

  .pools_5 .col_right {
    flex-basis: 100%;
    max-width: 100%;
    align-items: center;
  }

  .equipment h2 {
    font-size: 44px;
  }

  .equipment h3 {
    font-size: 35px;
  }

  .equipment .col-4 {
    flex-basis: 50%;
    max-width: 50%;
  }

  .calculate_form .col_left .input_wrap {
    flex-direction: column;
    align-items: flex-start;
  }

  .calculate_form .col_left input {
    order: 2;
  }

  .calculate_form .col_left .checkbox_wrap .ui-button {

    justify-content: flex-start;
  }

  .calculate_form .col_left .input_wrap span {
    max-width: 100%;
  }

  .calculate_form .col_left .input_wrap span, .calculate_form .col_left .checkbox_wrap .ui-button, .calculate_form .col_center .checkbox_wrap .ui-button, .calculate_form .col_right .checkbox_wrap .ui-button {
    font-size: 20px;
  }

  .calculate_form .col_left input {
    font-size: 20px;
  }

  .calculate_form .col_left .input_wrap {
    margin-bottom: 20px;
  }

  .calculate_form .col_left .checkbox_wrap {
    margin-top: 34px;
    margin-bottom: 28px;
  }

  .calculate_form .col_right textarea {
    padding-left: 15px;
    padding-top: 15px;
    font-size: 20px;
  }

  .warranty_item p {
    font-size: 22px;
  }

  .col_warranty {
    padding-left: 15px;
  }

  .works {

    .col-8 {
      flex-basis: 100%;
      max-width: 100%;
    }

    .col-4 {
      padding-left: 15px;
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  .top_contacts_row .col_left .contact_item a {
    font-size: 26px;
  }

  .top_contacts_row .col_left .contact_item p {
    font-size: 20px;
  }

  .top_contacts_row .col_left .contact_item:nth-of-type(1):before {
    top: 10px;
  }

  .top_contacts_row .col_left .contact_item:nth-of-type(2):before {
    top: -4px;
  }

  .bottom_contacts_row .contact_item a {
    font-size: 26px;
  }

  .bottom_contacts_row .address {
    font-size: 20px;
  }

  .bottom_contacts_row .contact_item p {
    font-size: 20px;
  }

  .bottom_contacts_row .social span {
    font-size: 20px;
  }
}


// Большие девайсы (большие десктопы, < 1200px)
@media (max-width: 1199.98px) {

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav li {
    width: 100%;
    border-right: 0;
  }

  .header_slogan {
    display: none;
  }

  .top_header {
    padding-top: 10px;
    padding-bottom: 5px;
    display: block !important;
  }

  .top_header .col_left a {
    height: auto;
  }

  .top_header .col_left a object {
    max-width: 150px;
  }

  .top_header .col_right a.blue_btn {
    display: none;
  }

  .top_header .col_right p {
    display: none;
  }

  .top_header .col_right a {
    font-size: 24px;
    line-height: 28px;
  }

  .top_header .row {
    align-items: center;
  }

  nav {
    display: none;
  }

  nav a {
    height: 56px;
  }

  .hamburger {
    height: 25px;
    margin-left: 20px;
    padding: 0;
    outline: none !important;
    border: none !important;
  }

  .top_header .header_contacts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  header {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  }

  .main_screen h1 {
    font-size: 52px;
  }

  .main_screen h1 .orange_text {
    font-size: 82px;
  }

  .main_screen {
    padding-top: 230px;
  }

  .main_screen h4 {
    margin-bottom: 320px;
  }

  .main_screen_bottom p {
    font-size: 22px;
  }

  .pools_1_top h3 {
    font-size: 26px;
    text-align: center;
  }

  .pools_1_top h4 {
    font-size: 22px;
    max-width: 306px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .pools1_item h5 {
    font-size: 26px;
  }

  .pools1_item p {
    font-size: 22px;
  }

  .row_pools1_items {
    margin-bottom: 40px;
  }

  .pools1_bottom h2 {
    font-size: 22px;
  }

  .pools2_item {
    padding-left: 0;
  }

  .pools2_item {
    margin-bottom: 160px;
  }

  .pools_2 .col_left .pools2_item:nth-of-type(1) p:before {
    top: -163px;
    left: 50%;
    margin-left: -45px;
  }

  .pools_2 .col_right .pools2_item:nth-of-type(1) p:before {
    top: -163px;
    left: 50%;
    margin-left: -45px;
  }

  .pools_2 .col_left .pools2_item:nth-of-type(2) p:before {
    top: -163px;
    left: 50%;
    margin-left: -45px;
  }

  .pools_2 .col_right .pools2_item:nth-of-type(2) p:before {
    top: -163px;
    left: 50%;
    margin-left: -45px;
  }

  .pools_2 .col_right .pools2_item_wrap .pools2_item:nth-of-type(1) {
    margin-bottom: 183px;
  }

  .pools2_item h4 {
    text-align: center;
    font-size: 26px;
  }

  .pools2_item p {
    text-align: center;
  }

  .pools2_item_wrap img {
    margin-bottom: 140px;
  }

  .pools2_item_wrap {

    .pools2_item:last-of-type {
      margin-bottom: 0;
    }
  }

  .pools_2 .top_form,
  .pools_3 .top_form,
  .pools_4 .top_form,
  .pools_5 .top_form,
  .equipment .top_form,
  .coating .top_form,
  .calculate_form .top_form,
  .warranty .top_form,
  .contacts .top_form {
    flex-direction: column;
    align-items: center;
  }

  .pools_2 .top_form input, .pools_3 .top_form input, .pools_4 .top_form input, .pools_5 .top_form input, .equipment .top_form input, .coating .top_form input, .calculate_form .top_form input, .warranty .top_form input, .contacts .top_form input {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
  }

  .pools_2 .top_form button, .pools_3 .top_form button, .pools_4 .top_form button, .pools_5 .top_form button, .equipment .top_form button, .coating .top_form button, .calculate_form .top_form button, .warranty .top_form button, .works .orange_btn, .contacts .top_form button {
    height: 72px;
    width: 100%;
    max-width: 500px;
  }

  .pools_2 .bottom_form .row div,
  .pools_3 .bottom_form .row div,
  .pools_4 .bottom_form .row div,
  .pools_5 .bottom_form .row div,
  .equipment .bottom_form .row div,
  .coating .bottom_form .row div,
  .calculate_form .bottom_form .row div,
  .warranty .bottom_form .row div,
  .contacts .bottom_form .row div{
    justify-content: center;
  }

  .pools_2 .bottom_form .row div:nth-of-type(2),
  .pools_3 .bottom_form .row div:nth-of-type(2),
  .pools_4 .bottom_form .row div:nth-of-type(2),
  .pools_5 .bottom_form .row div:nth-of-type(2),
  .equipment .bottom_form .row div:nth-of-type(2),
  .coating .bottom_form .row div:nth-of-type(2),
  .calculate_form .bottom_form .row div:nth-of-type(2),
  .warranty .bottom_form .row div:nth-of-type(2),
  .contacts .bottom_form .row div:nth-of-type(2) {
    margin-top: 30px;
    justify-content: center;
  }

  .pools3_item {
    margin-top: 140px;
    padding-left: 0;
    text-align: center;
  }

  .pools_3 .col_left .pools3_item p:before,
  .pools_3 .col_right .pools3_item p:before{
    top: -195px;
    left: 50%;
    margin-left: -47px;
  }

  .pools_3 .col_right .pools3_item_wrap .pools3_item:nth-of-type(1) {
    margin-top: 137px;
    margin-bottom: 196px;
  }

  .pools_3 .col_right .pools3_item:nth-of-type(1) p:before {
    top: -164px;
  }

  .pools4_item h4 {
    font-size: 26px;
  }

  .pools4_item p,
  .pools4_item ul li,
  .pools4_item h5 {
    font-size: 20px;
  }

  .pools_5 .col_right {
    text-align: center;
  }

  .equipment h2 {
    font-size: 40px;
  }

  .equipment h3 {
    font-size: 32px;
  }

  .equipment_item {
    text-align: center;
  }

  .col_equipment h4 {
    margin-bottom: 0;
    padding-left: 0;
  }

  .col_equipment {
    margin-top: 100px;
  }

  .col_equipment h4:before {
    top: -150px;
    left: 50%;
    margin-left: -62px;
  }

  .coating_item h3 {
    font-size: 30px;
  }

  .coating_item li {
    margin-bottom: 20px;
    font-size: 20px;
  }

  .calculate_form form>.row {
    flex-direction: column;
  }

  .calculate_form form .col_right {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .calculate_form form .col_left {
    max-width: 400px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .calculate_form form .col_center {
    max-width: 400px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
  }

  .calculate_form .top_form {
    margin-top: 60px;
  }

  .works .orange_btn {
    margin-right: auto;
  }

  .contacts .col_left {
    display: flex;
    justify-content: space-between;
  }

  .top_contacts_row .col_left .contact_item:nth-of-type(1) {
    margin-bottom: 50px;
  }

  .top_contacts_row {
    margin-bottom: 50px;
  }

                                                //THANKS PAGE

  .thanks_center h5 {
    font-size: 40px;
    margin-bottom: 20px;
  }

  .thanks_center h6 {
    font-size: 20px;
  }

  .thanks_center {
    max-width: 770px;
  }
}

// Средние девайсы («таблетки», < 992px)
@media (max-width: 991.98px) {

  .main_screen {
    padding-top: 190px;
  }


  .main_screen h1 {
    font-size: 38px;
    text-align: center;
  }

  .main_screen h4:after {
    display: none;
  }

  .main_screen_bottom .orange_btn:before {
    display: none;
  }

  .main_screen h1 .orange_text {
    font-size: 56px;
  }

  .main_screen h4 {
    display: block;
    max-width: 220px;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
  }

  .main_screen_bottom {
    text-align: center;
  }

  .main_screen h4 {
    margin-bottom: 140px;
  }

  .main_screen_bottom .orange_btn,
  .pools1_bottom a.orange_btn {
    height: 60px;
    margin-top: 15px;
    font-size: 20px;
    margin-right: auto;
  }

  .pools1_bottom {
    text-align: center;
  }

  .pools1_bottom h4 {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 24px;
  }

  .pools2_item h4 {
    font-size: 22px;
  }

  .pools_3 .col_left {
    margin-bottom: 40px;
  }

  .pools_4 .col_left .pools4_item_wrap {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .pools_4 .col_right .pools4_item_wrap {
    margin-right: auto;
  }

  .pools_5 .col-7 {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .col_pools5 {
    text-align: center;
    margin-bottom: 40px;
  }

  .pools_5 h2 {
    font-size: 38px;
  }

  .pools_5 ul {
    list-style: none;
  }

  .pools_5 .col_left img {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .pools_5 .col_right {

    img {
      max-width: 400px;
    }
  }

  .equipment h2 {
    font-size: 36px;
  }

  .equipment h3 {
    margin-top: 10px;
    font-size: 28px;
  }

  .col_equipment h4 {
    font-size: 28px;
  }

  .col_equipment p {
    font-size: 18px;
  }

  .coating_item {
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  .pools3_item_wrap {
    text-align: center;
  }

  .calculate_form h2 {
    font-size: 40px;
  }

  .calculate_form h4 {
    font-size: 26px;
  }

  .calculate_form .col_center .checkbox_wrap,
  .calculate_form .col_right .checkbox_wrap,
  .calculate_form form .col_center {
    margin-bottom: 30px;
  }

  .calculate_form h4 {
    margin-bottom: 38px;
  }

  .warranty_item {
    margin-bottom: 40px;
  }

  .works p {
    font-size: 18px;
  }

  .contacts h2 {
    font-size: 32px;
  }
}

// Малые девайсы («ландшафтные», < 768px)
@media (max-width: 767.98px) {

  .col_equipment h4 {
    height: auto;
  }

  .calculate_form h2 {
    font-size: 36px;
  }

  .calculate_form h4 {
    font-size: 24px;
  }

  .calculate_form h4 span:before {
    display: none;
  }

  .works h2 {
    font-size: 36px;
    text-align: center;
  }

  .contacts .col_left {
    flex-direction: column;
  }

  .top_contacts_row .col_left .contact_item:nth-of-type(1) {
    margin-bottom: 30px;
  }

  .contacts .col_left {
    margin-bottom: 50px;
  }

  .bottom_contacts_row .address {
    margin-top: 20px;
  }

  .mfp-content h2 {
    font-size: 22px;
  }

  .mfp-content input {
    font-size: 16px;
    height: 60px;
    padding-left: 20px;
  }

  .mfp-content .orange_btn {
    font-size: 16px;
    height: 60px;
  }

                          //THANKS PAGE

  .thanks_center {
    padding-left: 30px;
    padding-right: 30px;
  }

  .thanks_center h5 {
    font-size: 28px;
  }

  .thanks_center h6 {
    font-size: 16px;

    a {
      margin-top: 20px;
      font-size: 18px;
      display: block;
      border-bottom: 0 !important;
    }
  }
}


// Экстрамалые девайсы («телефоны», < 576px)
@media (max-width: 575.98px) {

  .top_header .col_right a {
    display: none;
  }

  .main_screen h1 {
    font-size: 26px;
    line-height: 32px;
  }

  .main_screen h1 .orange_text {
    font-size: 37px;
  }

  .main_screen h4 {
    margin-bottom: 100px;
    font-size: 20px;
    text-align: center;
  }

  .main_screen_bottom p {
    font-size: 18px;
    line-height: 24px;
  }

  .main_screen_bottom .orange_btn, .pools1_bottom a.orange_btn {
    font-size: 16px;
  }

  .pools_1_top h3 {
    font-size: 18px;
  }

  .pools_1_top h4 {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 36px;
    text-align: center;
  }

  .pools1_item h5 {
    font-size: 20px;
  }

  .pools1_item p {
    font-size: 20px;
  }

  .pools1_item img {
    margin-bottom: 13px;
  }
  .pools1_bottom h2 {
    font-size: 18px;
  }

  .pools1_bottom h4 {

    font-size: 20px;
  }

  .pools2_item h4 {
    font-size: 20px;
  }

  .pools2_item p {
    font-size: 14px;
    line-height: 22px;
  }

  .pools2_item_wrap img {
    margin-top: 20px;
    margin-bottom: 168px;
  }

  .pools_2 .top_form input, .pools_3 .top_form input, .pools_4 .top_form input, .pools_5 .top_form input, .equipment .top_form input, .coating .top_form input, .calculate_form .top_form input, .warranty .top_form input, .contacts .top_form input {
    font-size: 16px;
    height: 60px;
  }

  .pools_2 .top_form button, .pools_3 .top_form button, .pools_4 .top_form button, .pools_5 .top_form button, .equipment .top_form button, .coating .top_form button, .calculate_form .top_form button, .warranty .top_form button, .works .orange_btn, .contacts .top_form button {
    font-size: 16px;
    height: 60px;
  }

  .pools_2 .bottom_form .ui-button,
  .pools_3 .bottom_form .ui-button,
  .pools_4 .bottom_form .ui-button,
  .pools_5 .bottom_form .ui-button,
  .equipment .bottom_form .ui-button,
  .coating .bottom_form .ui-button,
  .calculate_form .bottom_form .ui-button,
  .warranty .bottom_form .ui-button,
  .contacts .bottom_form .ui-button {
    font-size: 10px;
  }

  .pools_2 .bottom_form .ui-state-active .ui-icon, .pools_2 .bottom_form .ui-button:active .ui-icon,
  .pools_3 .bottom_form .ui-state-active .ui-icon, .pools_3 .bottom_form .ui-button:active .ui-icon,
  .pools_4 .bottom_form .ui-state-active .ui-icon, .pools_4 .bottom_form .ui-button:active .ui-icon,
  .pools_5 .bottom_form .ui-state-active .ui-icon, .pools_5 .bottom_form .ui-button:active .ui-icon,
  .equipment .bottom_form .ui-state-active .ui-icon, .equipment .bottom_form .ui-button:active .ui-icon,
  .coating .bottom_form .ui-state-active .ui-icon, .coating .bottom_form .ui-button:active .ui-icon,
  .calculate_form .bottom_form .ui-state-active .ui-icon, .calculate_form .bottom_form .ui-button:active .ui-icon,
  .warranty .bottom_form .ui-state-active .ui-icon, .warranty .bottom_form .ui-button:active .ui-icon,
  .contacts .bottom_form .ui-state-active .ui-icon, .contacts .bottom_form .ui-button:active .ui-icon
  {
    margin-right: 10px !important;
  }

  .pools3_item h4 {
    font-size: 18px;
  }

  .pools3_item p {
    font-size: 14px;
    line-height: 22px;
  }

  .pools_3 .col_left .pools3_item p:before, .pools_3 .col_right .pools3_item p:before {
    top: -170px;
  }

  .pools_3 .col_right .pools3_item:nth-of-type(1) p:before {
    top: -152px;
  }

  .pools4_item h4 {
    font-size: 20px;
  }

  .pools4_item p, .pools4_item ul li, .pools4_item h5 {
    font-size: 14px;
    line-height: 22px;
  }

  .pools4_item ul {
    padding-left: 15px;
  }

  .pools_4 .col_right .pools4_item_wrap .pools4_item:nth-of-type(1) {
    margin-bottom: 0;
  }

  .pools_5 h2 {
    font-size: 24px;
  }

  .pools_5 h4 {
    font-size: 20px;
  }

  .pools_5 p {
    font-size: 14px;
    line-height: 22px;
  }

  .pools_5 li {
    font-size: 14px;
    line-height: 22px;
  }

  .pools_5 .col_right img {
    max-width: 300px;
  }

  .equipment h2 {
    font-size: 22px;
  }

  .equipment h3 {
    font-size: 18px;
  }

  .col_equipment h4 {
    font-size: 22px;
  }

  .col_equipment p {
    font-size: 16px;
  }

  .coating_item h3 {
    font-size: 24px;
  }

  .coating_item ul {
    padding-left: 15px;
  }

  .coating_item li {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 22px;
  }

  .calculate_form h2 {
    font-size: 26px;
  }

  .calculate_form h4 {
    font-size: 18px;
  }

  .calculate_form .col_left .input_wrap span, .calculate_form .col_left .checkbox_wrap .ui-button, .calculate_form .col_center .checkbox_wrap .ui-button, .calculate_form .col_right .checkbox_wrap .ui-button {
    font-size: 16px;
  }

  .calculate_form .col_left input {
    max-width: 300px;
    height: 60px;
    font-size: 16px;
    padding-left: 20px;
  }

  .calculate_form .col_left .checkbox_wrap .ui-button .ui-icon {
    height: 20px;
    width: 20px;
  }

  .calculate_form .col_left .input_wrap span, .calculate_form .col_left .checkbox_wrap .ui-button, .calculate_form .col_center .checkbox_wrap .ui-button, .calculate_form .col_right .checkbox_wrap .ui-button {
    font-size: 12px;
    line-height: 18px;
  }

  .calculate_form .col_center .checkbox_wrap .ui-button .ui-icon,
  .calculate_form .col_right .checkbox_wrap .ui-button .ui-icon {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }

  .calculate_form .col_right textarea {
    height: 150px;
    font-size: 16px;
  }

  .warranty_item p {
    font-size: 16px;
  }

  .warranty_bottom h3 {
    margin-bottom: 14px;
    font-size: 26px;
  }

  .warranty_bottom ul {
    list-style: none;
  }

  .warranty_bottom h4 {
    font-size: 18px;
    text-align: center;
  }

  .warranty_bottom p {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .works h2 {
    font-size: 26px;
  }

  .works p {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
  }

  .contacts h2 {
    margin-bottom: 20px;
    font-size: 26px;
  }

  .top_contacts_row .col_left .contact_item:nth-of-type(1):before,
  .top_contacts_row .col_left .contact_item:nth-of-type(2):before,
  .bottom_contacts_row .contact_item:nth-of-type(1):before,
  .bottom_contacts_row .contact_item:nth-of-type(2):before,
  .bottom_contacts_row .address:before,
  .bottom_contacts_row .social span {
    display: none;
  }

  .top_contacts_row .col_left .contact_item:nth-of-type(1) {
    padding-left: 0;
    text-align: center;
    font-size: 22px;
    line-height: 22px;
  }

  .top_contacts_row .col_left .contact_item {
    padding-left: 0;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
  }

  .top_contacts_row .col_left .contact_item a {
    font-size: 22px;
  }

  .bottom_contacts_row .contact_item:nth-of-type(1) {
    padding-left: 0;
    text-align: center;
  }

  .bottom_contacts_row .contact_item {
    padding-left: 0;
    text-align: center;
  }

  .bottom_contacts_row .contact_item a {
    font-size: 22px;
  }

  .bottom_contacts_row .address {
    padding-left: 0;
    text-align: center;
  }

  .bottom_contacts_row .social {
    justify-content: center;

    a {
      img {
        width: 40px;
      }
    }

    a:last-of-type {
      margin-right: 0;
    }
  }

  .contacts {
    padding-top: 60px;
  }

  .bottom_contacts_row .contact_item:nth-of-type(1) {
    margin-bottom: 20px;
  }

  .contacts .col_left {
    margin-bottom: 20px;
  }

  .bottom_contacts_row .address {
    margin-top: 0 !important;
    margin-bottom: 20px;
  }

  .pools_2,
  .pools_3,
  .pools_4,
  .pools_5,
  .equipment,
  .coating,
  .calculate_form,
  .works {
    padding-top: 60px;
  }
}